
import { defineComponent, reactive, ref, PropType, toRefs } from "vue";
import LoginStore from "@/store/modules/login";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import { useRouter, useRoute } from "vue-router";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
export default defineComponent({
  name: "",
  created(){
    cookies.set("TOKEN_ACCOUNT",'')
  },
  setup() {
    sessionStorage.clear()
    const loginStore = getModule(LoginStore, store);
    const data = reactive({
      loginForm: {
        username: "admin",
        password: "qpZM@!#0102",
        checkLogin: false,
      },
      loginRules: {
        username: [{ required: true, message: "请输入账号" }],
        password: [{ required: true, message: "请输入密码" }],
      },
      layout: {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 },
      },
      loading: false,
    });
    const loginRefs = ref();
    const router = useRouter();
    const handleLogin = () => {
      loginRefs.value.validate().then(() => {
        data.loading = true;
         loginStore
          .login(data.loginForm as AxiosRequestConfig)
          .then(async() => {
            data.loading = false;
            router.push("/home/homeindex");
          })
          .catch(() => {
            data.loading = false;
          });
      });
    };
    return {
      ...toRefs(data),
      handleLogin,
      loginRefs,
    };
  },
});
